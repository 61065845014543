import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ref } from 'vue';
export default {
  __name: 'index',
  props: ['content'],
  setup(__props) {
    const props = __props;
    const textRef = ref();
    const visible = ref(false);
    const mouseenter = () => {
      visible.value = !noEllipsis(textRef.value);
    };
    const noEllipsis = el => {
      // copy 元素，获取自然填充时的宽度
      const {
        width: scrollWidth
      } = getElementRect(el);
      // 获取元素实际宽度，不用offsetWidth，是因为Chrome调整缩放比例对offsetWidth有影响
      const {
        width: offsetWidth
      } = el.getBoundingClientRect();
      // 兼容 IE， 临界值有偏差
      return scrollWidth - offsetWidth <= 0.001;
    };
    const getElementRect = el => {
      const style = getComputedStyle(el);
      const isInput = el.tagName.toLowerCase() === 'input';
      if (isInput) {
        const text = el.value || el.getAttribute('placeholder');
        el = document.createElement('div');
        el.innerHTML = text;
      } else {
        el = el.cloneNode(true);
      }
      el.style.display = 'inline';
      el.style.width = 'auto';
      el.style.visibility = 'hidden';
      el.style.fontSize = style.fontSize;
      el.style.fontFamily = style.fontFamily;
      el.style.fontWeight = style.fontWeight;
      el.style.letterSpacing = style.letterSpacing;
      el.style.wordSpacing = style.wordSpacing;
      el.style.padding = style.padding;
      el.style.border = style.border;
      el.style.boxSizing = style.boxSizing;
      document.body.appendChild(el);
      const rectCopy = el.getBoundingClientRect();
      document.body.removeChild(el);
      return rectCopy;
    };
    return (_ctx, _cache) => {
      const _component_el_tooltip = _resolveComponent("el-tooltip");
      return _openBlock(), _createBlock(_component_el_tooltip, {
        visible: visible.value,
        placement: "top"
      }, {
        content: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(props.content), 1)]),
        default: _withCtx(() => [_createElementVNode("p", {
          ref_key: "textRef",
          ref: textRef,
          class: _normalizeClass(["ws_text_overflow", _ctx.$attrs.class]),
          onMouseenter: mouseenter,
          onMouseleave: _cache[0] || (_cache[0] = $event => visible.value = false)
        }, [_renderSlot(_ctx.$slots, "default")], 34)]),
        _: 3
      }, 8, ["visible"]);
    };
  }
};